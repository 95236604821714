<template>
    <div>Purchase History</div>
</template>
<script setup>
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia'
import { useSelfPurchaseHistory } from "@/core/modules/self/purchasehistory"
const store = useSelfPurchaseHistory()

onMounted(() => {
    store.getPurchaseHistory()
})
</script>
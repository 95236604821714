import axios from "axios";
import { defineStore } from "pinia";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import {useManageUser} from '@/core/modules/auth/user'
import { storeToRefs } from 'pinia'


export const usePayment = defineStore(
  "payment",
  {
    state: () => ({
      errors: null,
      loaderStatus: false,
      successresponse: null,
      booking_id: null
    }),
    getters: {
      getPaymentErrors: (state) => {
        return state.errors;
      },
      getLoaderStatus: (state) => {
        return state.loaderStatus;
      },
      getSuccessResponse: (state) => {
        return state.successresponse
      },
      getBookingId : (state) => {
        return state.booking_id
      }
    },
    actions: {
      async savePayment(scheduleID, deptID,rate) {
        try {
          this.loaderStatus = true;
          await axios
          .get("/onlinepayment", {
            params: {
              schedule_id: scheduleID ,
              dept_id: deptID,
              amount: rate
            }
          })
          .then((response) => {
            this.loaderStatus = false;
            if(response.status == 200){
            window.location.href=response.data.response.payment_url;
            }
          })
          .catch((error) => {
            this.loaderStatus = false;
            this.errors = error.data;
            Swal.fire({
              title: error.response.data.msg,
              text: error.response.data.error,
              icon: 'info',
              confirmButtonText: 'OK'
            })
            .then(() => {
              window.location.reload()
          });
            
          });
        }
        catch (error) {
          this.errors = error.data;
          Swal.fire({
            title: error.response.data.msg,
            text: error.response.data.error,
            icon: 'error',
            confirmButtonText: 'OK'
          })
          .then(() => {
            window.location.reload()
        });
        }
      },
      async checkSlotAvaliabilty(schedule_id) {
        this.errors = null;
        const { getIsAuthorized}  =  storeToRefs(useManageUser())

        try {
          this.loaderStatus =  true;
          await axios
            .get("/checkSlotAvaliabilty/" + schedule_id)
            .then((response) => {
              this.loaderStatus =  false;
              if(response.status == 200){
                this.booking_id = response.data.booking_id
                if(getIsAuthorized.value){
                this.savePatient(response.data.booking_id, response.data.schedule_id)}
                else {
                  this.$router.push({ name: 'page.auth.signup'})
                }
              }
            })
            .catch((error) => {
              Swal.fire({
                title: error.response.data.msg,
                text: error.response.data.error,
                icon: 'info',
                confirmButtonText: 'OK'
              })
              .then(() => {
                if(window.localStorage.getItem('bookpay')){
                  window.localStorage.removeItem('bookpay')
                }
                window.location.reload();

              })
            });
        } catch (error) {
          this.errors = error.response.data;
          this.loaderStatus =  false;
        }
      },
      async savePatient(bookid, scheduleid) {
        this.errors = null;
        let booking_obj = {

        }
      const { getIsAuthorized}  =  storeToRefs(useManageUser())
        if(getIsAuthorized.value){
        booking_obj = {
          booking_id : bookid,
          schedule_list_id : parseInt(scheduleid)
        }
      }
      else {
        booking_obj = bookid
      }
        try {
          this.loaderStatus =  true;
          await axios
            .post("/bookAppointment", booking_obj)
            .then((response) => {
              this.loaderStatus =  false;
              if(response.status === 200) {
                this.successresponse = response.data;
                Swal.fire({
                  title: response.data.message,
                  text: 'Date and Time :' + response.data.appointment_date + ' ' + response.data.appointment_time,
                  icon: 'success',
                  confirmButtonText: 'OK'
                })
                .then(() => {
                  this.$router.push({ name: "page.successappointment" });
              });
              
              }
             
            })
            .catch((error) => {

              this.loaderStatus=false;
              Swal.fire({
                title: error.response.data.error,
                text: error.response.data.message,
                icon: 'info',
                confirmButtonText: 'OK'
              })
              .then(() => {
                
                if(window.localStorage.getItem('bookpay')){
                  window.localStorage.removeItem('bookpay')
                }
        if(getIsAuthorized.value){
          window.location.reload()
        }
        else {

          this.$router.push({ path: "/appointment/doctor/" + window.localStorage.getItem('opd_id') });
        }

              })
            });
        } catch (error) {
          this.errors = error.response.data;
          this.loaderStatus =  false;
        }
      },
  
    async paymentResponse(body ){
      try {
        this.loaderStatus = true;
        await axios
        .post("/verifyPayment", body)
        .then((response) => {
          if(response.status == 200) {
            this.loaderStatus = false;
            this.successresponse = response.data
            Swal.fire({
              title: 'Succesfully Paid',
              text: response.data.message + ' ' +'for' + ' ' + response.data.appointment_date + ' ' + response.data.appointment_time ,
              icon: 'success',
              confirmButtonText: 'OK'
            })
            .then(() => {
              this.$router.push({ name: "page.successappointment" });
          })
        }
        })
        .catch((error) => {
          this.loaderStatus = false;
          this.errors = error.response.data;
        });
      }
      catch (error) {

        this.loaderStatus = false;
        this.errors = error.response.data;
      }
    },
  }
  }
);

<template>
  <web-header v-if="route.name!='page.download.app'"></web-header>
  <div class="osahan-profile" v-if="route.name!='page.download.app'">
    <div class="position-relative" :class="!getIsAuthorized && route.path=='/' ?'container':'container-fluid'">
      <!-- :class="!route.meta.requiresAuth ?'container':'container-fluid'" -->
      <div class="pt-2 osahan-profile row"> 
       
        <router-view></router-view>
      </div>

    </div>
  </div>
  <div v-else>
    <router-view></router-view>
  </div>
  <web-footer v-if=" route.name!='page.auth.login' "></web-footer>
</template>

<script setup>
import webHeader from '@/includes/header'
import webFooter from '@/includes/footer'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import {useManageUser} from '@/core/modules/auth/user'
const manageUserStore  = useManageUser()
const { getIsAuthorized}  =  storeToRefs(useManageUser())
const route =  useRoute()
</script>

import axios from "axios";
import { defineStore } from "pinia";

export const useSelfInvestigationReport = defineStore(
  "selfInvestigationReport",
  {
    state: () => ({
      errors: null,
      loaderStatus: false,
      investigationReportData: "",
      reportIframe: "",
      pdfLoader: false,
      investigationReportDashboard: ""
    }),
    getters: {
      getErrors: (state) => {
        return state.errors;
      },
      getLoaderStatus: (state) => {
        return state.loaderStatus;
      },
      getInvestigationReportData: (state) => {
        return state.investigationReportData;
      },
      getReportIframe: (state) => {
        return state.reportIframe;
      },
      getPdfLoader: (state) => state.pdfLoader,
      getInvestigationReportDashboard : (state) => {
        return state.investigationReportDashboard
      }
    },
    actions: {
      async getInvestigationReportDashboardData() {
        try {
          await axios
            .get("/get/bill-list")
            .then((response) => {
              this.investigationReportDashboard = response.data;
            })
            .catch((error) => {
              console.log(error)
            })
        }
        catch (error) {
          console.log(error)
        }
      },
      async getInvestigationReport() {
        try {
          this.setErrors(null)
          this.loaderStatus = true;
          await axios
            .get("/get/bill-list")
            .then((response) => {
              this.loaderStatus = false;
              this.investigationReportData = response.data.invesgitionReports;
              this.investigationReportData.forEach((element, index) => {
                element.is_active = false;
                if (index == 0) {
                  element.is_active = true;
                  this.getInvestigationReportDetail(
                    element.branch_id,
                    element.bill_no
                  );
                }
              });
            })
            .catch((error) => {
              this.setErrors(error.response.data)
              this.loaderStatus = false;
            });
        } catch (error) {}
      },
      async getInvestigationReportDetail(branch, bill_no) {
        try {
          this.pdfLoader = true;
          await axios
            .get("/v2/pdf/labreport/" + branch + "/" + bill_no,{
              responseType: "blob",
            })
            .then((response) => {
              const blob = new Blob([response.data], { type: "application/pdf" });
              const objectUrl = URL.createObjectURL(blob);
              this.reportIframe = objectUrl;
              this.pdfLoader = false;
            })
            .catch((error) => {
              this.pdfLoader = false;
            });
        } catch (error) {}
      },

      setActiveVisit(branch,billNo) {
        this.investigationReportData.forEach((element) => {
          element.is_active = false;
          if (element.bill_no == billNo) {
            element.is_active = true;
          }
        });
        this.getInvestigationReportDetail(branch,billNo);
      },
      setErrors(error) {
        this.errors = error;
        },
    },
    
  }
);

import axios from "axios";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { Modal } from 'bootstrap'
export const useSelfAppointment = defineStore("selfAppointment", {
    state: () => ({
        errors: null,
        loaderStatus: false,
        appointmentData: "",
        doctorsData: "",
        doctorSchedule: "",
        selectedDoctor: "",
        appointmentHistory: "",
        todayData: "",
        immigrationDoctorDetail : "",
        paymentInfo : "",
        specializationData: "",
        checkReschedule: "",
        currentScheduleId: "",
        bookingId: "",
        departmentList: '',
        next_schedule_date: '',
        scheduleTimeSlots: '',
        opd_charge: '',
        multi_pricecat_dept_ids: '',
        multiple_charges: ''
    }),
    getters: {
        getErrors: (state) => {
            return state.errors;
        },
        getLoaderStatus: (state) => {
            return state.loaderStatus;
        },
        getAppointmentData: (state) => {
            return state.appointmentData;
        },
        getDoctorsData: (state) => {
            return state.doctorsData;
        },
        getSelectedDoctor: (state) => {
            return state.selectedDoctor;
        },
        getDoctorSchedule: (state) => {
            return state.doctorSchedule;
        },
        getAppointmentHistory: (state) => {
            return state.appointmentHistory
        },
        getTodayData: (state) => {
            return state.todayData
        },
        getImmigrationDoctorDetail: (state) => {
            return state.immigrationDoctorDetail
        },
        getPaymentInfoData: (state) => {
            return state.paymentInfo
        },
        getSpecializationData: (state) => {
            return state.specializationData
        },
        checkRescheduleFlag: (state) => {
            return state.checkReschedule
        },
        getCurrentScheduleId : (state) => {
            return state.currentScheduleId
        },
        getBookingId: (state) => {
            return state.bookingId
        },
        getDepartmentListData: (state) => {
            return state.departmentList
        },
        getNextScheduleDate: (state) => {
            return state.next_schedule_date
        },
        getScheduleTimeSlotsData: (state) => {
            return state.scheduleTimeSlots
        },
        getDepartmentOpdChargeData: (state) => {
            return state.opd_charge
        },
        getMultiPriceCatDeptId : (state) => {
            return state.multi_pricecat_dept_ids
        },
        getMultipleCharges : (state) => {
            return state.multiple_charges
        }
        
    },
    actions: {
        async getAppointment() {
            try {
                this.setErrors(null);
                this.loaderStatus = true;
                await axios
                    .get("fetchSpecialities/")
                    .then((response) => {
                        this.loaderStatus = false;
                        this.appointmentData = response.data.data;
                        this.multi_pricecat_dept_ids = response.data.multi_pricecat_dept_ids
                    })
                    .catch((error) => {
                        this.setErrors(error.response.data);
                        this.loaderStatus = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        async getDoctors(id) {
            try {
                this.setErrors(null);
                this.loaderStatus = true;
                await axios
                    .get("departmentDoctor/" + id)
                    .then((response) => {
                        if (response.status === 200) {
                            this.loaderStatus = false;
                            // console.log(response);
                            this.doctorsData = response.data.data;
                            
                            this.$router.push({ path: "/appointment/doctor/" + id });
                        }
                    })
                    .catch((error) => {
                        this.setErrors(error.response.data);
                        this.loaderStatus = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        async getNextScheduleDateForCalendar(id) {
            try {
                this.setErrors(null);
                this.loaderStatus = true;
                await axios
                    .get("getNextScheduleDateForCalendar", {
                        params: {
                            department_id : id
                        }
                        
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.loaderStatus = false;
                            this.next_schedule_date = response.data.date;
                        }
                    })
                    .catch((error) => {
                        this.setErrors(error.response.data);
                        this.loaderStatus = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        async getScheduleTimeSlots(dateValue,id) {
            try {
                this.setErrors(null);
                this.loaderStatus = true;
                await axios
                    .get("getScheduleTimeSlots", {
                        params: {
                            date : dateValue,
                            department_id : id
                        }
                        
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.loaderStatus = false;
                            this.scheduleTimeSlots = response.data
                        }
                    })
                    .catch((error) => {
                        this.setErrors(error.response.data);
                        this.loaderStatus = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        async getDepartmentOpdCharge(id, cat_id){
            this.errors = null;
            let patient_cat_id;
            if(!cat_id) {
                patient_cat_id = 1
            }
            else {
                patient_cat_id = cat_id
            }
            try {
                this.loaderStatus = true;
                await axios
                    .get('/getDepartmentOpdCharge/' + id, {
                        params: {
                            patient_cat_id : patient_cat_id
                        }
                        
                    })
                    .then((response) => {
                        this.loaderStatus = false;
                        this.opd_charge = response.data.total_amount;
                        this.$router.push({ path: "/appointment/doctor/" + id });

                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                this.loaderStatus = false;
            }
        },
        setErrors(error) {
            this.errors = error;
        },
        // async savePatient(credentials) {
        //     this.errors = null;
        //     try {
        //       this.loaderStatus =  true;
        //       await axios
        //         .post("/bookAppointment", credentials)
        //         .then((response) => {
        //           this.loaderStatus =  false;
        //           if(response.status === 200) {
        //             this.successresponse = response.data;
        //             Swal.fire({
        //               title: response.data.message,
        //               text: 'Date and Time :' + response.data.appointment_date + '' + response.data.appointment_time,
        //               icon: 'success',
        //               confirmButtonText: 'OK'
        //             })
        //             .then(() => {
        //               this.$router.push({ name: "page.successappointment" });
        //           });
                  
        //           }
                 
        //         })
        //         .catch((error) => {
        //           this.errors = error.response.data;
        //           this.loaderStatus =  false;
        //         });
        //     } catch (error) {
        //       this.errors = error.response.data;
        //       this.loaderStatus =  false;
        //     }
        //   },
        async getScheduleDates(doc_id, dep,  year, month_index, doctor, is_reschedule) {
            var immigration;
            if(dep === 49){
                immigration = true
            }
            else {
                immigration = null
            }
            try {
                this.setErrors(null);
                this.loaderStatus = true;
                await axios
                    .get("fetchScheduleDates", {
                        params: {
                            month:  month_index,
                            year: year,
                            dept_id: dep,
                            doc_id: doc_id,
                            is_immigration: immigration,
                        },
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.loaderStatus = false;
                            this.doctorSchedule = response.data.data;
                            this.selectedDoctor = doctor;
                            this.specializationData = this.doctorsData.speciality;
                            this.checkReschedule = is_reschedule;
                            this.$router.push({ name: "page.doctorschedule" });
                        }
                        else if(response.status === 204){
                            this.loaderStatus = false;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loaderStatus = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        async bookappointment(body,bp) {
            this.errors = null;
            try {
                this.loaderStatus = true;
                await axios
                    .post("/bookAppointment", body)
                    .then((response) => {
                        this.loaderStatus = false;
                        if(response.data.status == 400){
                            Swal.fire({
                                title: response.data.title,
                                text: response.data.message,
                                icon: 'error',
                                confirmButtonText: 'OK'
                              })
                             
                        } else if (response.data.status == 200){
                            toast.success(response.data.message, {
                                autoClose: 1000,
                              });
                            if(bp === 'pay'){
                                let  modal1 = Modal.getOrCreateInstance('#payappointment');
                                modal1.show();}
                            else {
                                this.$router.push({ name: "page.self.appointment" });
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        this.loaderStatus = false;
                        Swal.fire({
                    title: error.response.data.title,
                    text: error.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'OK'
                  })
                    });
            } catch (error) {
                this.loaderStatus = false;
                Swal.fire({
                    title: error.response.data.title,
                    text: error.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'OK'
                  })
            }
        },
        async fetchAppointmentHistory(tab, patient_id) {
            this.errors = null;
            try {
                this.loaderStatus = true;
                await axios
                    .get('/fetchAppointmentHistory/' + tab + "/" + patient_id)
                    .then((response) => {
                        this.loaderStatus = false;
                        this.appointmentHistory = response.data.appointment_history.data;
                        this.todayData = response.data.appointment_history.today;
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                        console.log(error)
                    })
            }
            catch (error) {
                this.loaderStatus = false
            }
        },
        async fetchImmigrationDoctorDetails(){
            this.errors = null;
            try {
                this.loaderStatus = true;
                await axios
                    .get('/fetchImmigrationDoctorDetails')
                    .then((response) => {
                        this.loaderStatus = false;
                        this.immigrationDoctorDetail = response.data.doctor;
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                this.loaderStatus = false;
            }
        },
        async getPaymentInfo(id){
            this.errors = null;
            try {
                this.loaderStatus = true;
                await axios
                    .get('getPaymentInfo?schedule_list_id='+id)
                    .then((response) => {
                        this.loaderStatus = false
                        this.paymentInfo = response.data
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                this.loaderStatus = false
            }
        },
        async reschedule(body) {
            this.errors = null;
            try {
                this.loaderStatus = true;
                await axios
                    .post('reschedule_appointment', body)
                    .then((response) => {
                        this.loaderStatus = false
                        if(response.status === 200){
                            this.$router.push({ name: "page.self.appointment" });
                            toast.success(response.data.message, {
                                autoClose: 1000,
                              });
                        }
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                this.loaderStatus = false
            }
        },
        getAppointmentId(schedule_id) {
            this.currentScheduleId = schedule_id
        },
        async getBookingIdData(body){
            this.errors = null;
            try {
                this.loaderStatus = true;
                await axios
                    .post('getBookingId', body)
                    .then((response) => {
                        this.loaderStatus = false;
                        if(response.data.code === 200){
                            this.bookingId = response.data;
                            let  modal1 = Modal.getOrCreateInstance('#rescheduleappointment');
                            modal1.show();
                        }
                        else if(response.data.code === 400){
                            Swal.fire({
                                title: 'Error',
                                text: response.data.message,
                                icon: 'error',
                                confirmButtonText: 'OK'
                              })
                            
                        }
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                this.loaderStatus = false
            }
        },
        async getDepartmentList(){
            this.errors = null;
            try {
                this.loaderStatus = true;
                await axios
                    .get('/department/active_list')
                    .then((response) => {
                        this.loaderStatus = false;
                        this.departmentList = response.data.data.department_list
                        this.multi_pricecat_dept_ids = response.data.multi_pricecat_dept_ids

                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                this.loaderStatus = false;
            }
        },
        async getMultipeChargesForDepartmentOpd(id){
            this.errors = null;
            try {
                // this.loaderStatus = true;
                await axios
                    .get('/getMultipeChargesForDepartmentOpd/' + id)
                    .then((response) => {
                        this.loaderStatus = false;
                        this.multiple_charges = response.data
                        // this.$router.push({ path: "/appointment/doctor/" + id });
                    })
                    .catch((error) => {
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                this.loaderStatus = false;
            }
        },
        
    },
    persist: true,
});

<template>
    <paywellness :service_name="getSelectedWellnessPlan.name" :user_name="getUser.patient_name" :user_address="getUser.address" :user_phone="getUser.phone_no" :user_date="dateVal" :user_time="timeVal" :grand_total="getSelectedWellnessPlan.total"></paywellness>
    <modalComp :idmodal="'bookwellness'" :show-header="true" :show-footer="true" :size="'modal-md'">

        <template v-slot:header>
            <h5 class="modal-title">{{ getSelectedWellnessPlan.department }}</h5>
            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
        </template>

        <template v-slot:body>
            <SpinnerDataLoader :classname="'text-primary'" v-if="getLoaderStatus"></SpinnerDataLoader>
            <div v-if="!getLoaderStatus" class="strongpassword">
                <span style="font-size: var(--large---regular)">Package Name : <span class="fw-bold">{{
                    getSelectedWellnessPlan.name }}</span></span>
                <br>
                <span style="font-size: var(--large---regular)">Grand Total :
                    <span class="fw-bold">Rs {{ getSelectedWellnessPlan.total }}</span>
                </span>
                <br />
                <Datepicker class="py-15" :disabled-dates="disabledDates" v-model="date"
                    placeholder="Select Date and Time" />
            </div>
        </template>

        <template v-slot:footer>
            <div class="col-6 m-0 p-0">
                <button @click="book_wellness('book')" type="button" class="btn border-top btn-lg w-100"
                    data-bs-dismiss="modal">
                    Book
                </button>
            </div>
            <div class="col-6 m-0 p-0">
                <button data-bs-dismiss="modal" @click="book_wellness('pay')" type="button"
                    class="btn btn-primary btn-lg w-100">
                    Book and Pay
                </button>
            </div>
        </template>
        
    </modalComp>
</template>

<script setup>
import "@/assets/css/datepicker.css"
import { onMounted, ref, computed } from "vue";
import SpinnerDataLoader from "@/components/loaders/spinnerloader";
import modalComp from "@/components/common/modal";
import { storeToRefs } from "pinia";
import { useManageUser } from "@/core/modules/auth/user";
import { useSelfWellness } from "@/core/modules/self/wellness";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import paywellness from "@/components/modal/paywellness";
moment().format();
const { getUser } = storeToRefs(useManageUser());

const store = useSelfWellness();
const {
    getSelectedWellnessPlan,
    getPackageItems,
    getSimilarServices,
    getLoaderStatus,
} = storeToRefs(useSelfWellness());
const selectedWellnessPackage = ref("");
const props = defineProps({
    wellness_plan_name: String,
    getCurrentDate: String,
});
const date = ref("");
const disabledDates = (dates) => {
    return dates < new Date(Date.now() - 86400000);
};
const dateVal = ref("");
const timeVal = ref("");

onMounted(() => {
    let bookmodel = document.getElementById("bookwellness");
    bookmodel.addEventListener("hidden.bs.modal", function () {
        date.value = "";
    });
});

function selectPackage(wellness_name, id) {
    selectedWellnessPackage.value = wellness_name;
    store.fetchWellnessPlanData(id);
}

function book_wellness(bp) {
    dateVal.value = moment(date.value).format("YYYY-MM-DD");
    timeVal.value = moment(date.value).format("HH:mm");
    var bookdata = {
        item_id: getSelectedWellnessPlan.value.item_id,
        id: getSelectedWellnessPlan.value.id,
        appointment_date: dateVal.value,
        appointment_time: timeVal.value,
        patient_id: getUser.value.patient_id,
        total_amt: getSelectedWellnessPlan.value.total,
    };
    store.onlineBookWellness(bookdata, bp);
}

// function pay_wellness(){
//     if(!date.value) {
//         Swal.fire({
//             title: 'Error',
//             text: 'Please Select Date and Time',
//             icon: 'error',
//             confirmButtonText: 'OK'
//             })
//             return
//     }
//     dateVal.value = moment(date.value).format('YYYY-MM-DD');
//     timeVal.value = moment(date.value).format('HH:mm');
// }
</script>

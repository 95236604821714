import { createApp, markRaw } from "vue";
import { createPinia } from "pinia"; // Import
const pinia = createPinia();
import App from "./App.vue";
import router from "./router/routes.js";
import axios from "axios";
import VueAxios from "vue-axios";

import piniaPluginPersistedState from "pinia-plugin-persistedstate"

// boostrap start
import "bootstrap/dist/css/bootstrap.css";


//custom css start
import "@/assets/css/style.css";
//custom css end

// fontawesome start
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
/* import specific icons */
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas, far);
// fontawesome end

import { API_URL } from "@/core/services/config";

import { createDeviceDetector } from "next-vue-device-detector";

export const device = createDeviceDetector();

import "vue3-toastify/dist/index.css";
import "sweetalert2/dist/sweetalert2.min.css";

// pagination
import Pagination from 'v-pagination-3';

// calendar
import { DatePicker } from 'v-calendar';
import 'v-calendar/style.css';


axios.defaults.baseURL = API_URL;

//for using this.$router in pinio store
pinia.use(({ store }) => {
  store.$router = markRaw(router);
});
pinia.use(piniaPluginPersistedState)

import bootstrap from "bootstrap/dist/js/bootstrap.js";
//bootstrap end
const app = createApp(App);

// app.use(router)
app.use(pinia);
app.use(DatePicker, {})

import { useManageUser } from "@/core/modules/auth/user";

router.beforeEach(async (to, from, next) => {
  const manageUserStore = useManageUser();
  await manageUserStore.getUserDetails();
  // this condn will check the device type if device type is android or ios it will redirect page to download app where user can download ios or android app
  // if ((device.android || device.ios) && to.name != "page.download.app")
  // next({ name: "page.download.app"});
  if (to.meta.requiresAuth) {
    if (!manageUserStore.isAuthorized) next({ name: "page.auth.login" });
    next();
  } else {
    if (manageUserStore.isAuthorized) next();
    next();
  }
});
createApp(App)
  .use(router, bootstrap, VueAxios, axios, device)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component('pagination', Pagination)
  .component('VDatePicker', DatePicker)
  .mount("#app");

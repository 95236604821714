<template>
    <modalComp :idmodal="'paywellness'" :show-header="true" :show-footer="true" :size="'modal-md'">
        <template v-slot:header>
            <h5 class="modal-title">Payment Confirmation</h5>
            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
        </template>

        <template v-slot:body>
            <span class="fw-bold">Purchase Summary</span>
            <div class="purchase-summary-container">
                <span class="fw-bold">Service: </span> &nbsp;  &nbsp;  &nbsp; <span>{{ service_name }}</span> <br>
                <span class="fw-bold">Name: </span><span> &nbsp;  &nbsp;  &nbsp;  &nbsp; {{ user_name }}</span> <br>
                <span class="fw-bold">Address: </span> &nbsp;  &nbsp;  <span>{{ user_address }}</span> <br>
                <span class="fw-bold">Phone: </span> &nbsp;  &nbsp;  &nbsp;  &nbsp; <span>{{ user_phone }}</span> <br>
                <span class="fw-bold">Date: </span> &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  <span>{{ user_date }}</span> <br>
                <span class="fw-bold">Time: </span> &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  <span>{{ user_time }}</span> <br>
                <div style="border-bottom: 1px solid var(--foundation----primary---p200); margin: 10px 0px" ></div>
                <span class="fw-bold">Grand Total: </span> &nbsp;<span>Rs. {{ grand_total }}</span> <br>
            </div>
            
        </template>
        <template v-slot:footer>
            <div class="col-6 m-0 p-0">
                <button @click="book_wellness('book')" type="button" class="btn border-top btn-lg w-100" data-bs-dismiss="modal">
                    Pay Via Khalti 
                        <img style="height: 24px; margin-left: 5px;" src="../../assets/icons/khaltipng.png" alt="khalti">
                </button>
            </div>
            <div class="col-6 m-0 p-0">
                <button data-bs-dismiss="modal" @click="book_wellness('pay')"  type="button" class="btn btn-primary btn-lg w-100">
                    
                        Pay Via Esewa
                    <img style="height: 24px; margin-left: 5px;" src="../../assets/icons/esewapng.png" alt="esewa">
                </button>
            </div>
        </template>
    </modalComp>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import modalComp from '@/components/common/modal'
import { useSelfWellness } from "@/core/modules/self/wellness";
import { onMounted } from 'vue';
const store = useSelfWellness();
const { getShowModal } = storeToRefs(useSelfWellness())
const props = defineProps({
    service_name : String,
    user_name: String,
    user_address: String,
    user_phone: String,
    user_date: String,
    user_time: String,
    grand_total: Number
})

</script>

<style scoped>

</style>


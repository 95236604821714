import axios from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";
import { useJwtService } from "@/core/services/jwt";
import { useComponentCardProfile } from '@/core/modules/components/card/profile'
import {useSelectAccount} from '@/core/modules/manage/selectaccount'
import {useManageUser} from '@/core/modules/auth/user'
import Swal from 'sweetalert2/dist/sweetalert2.js'
// import {jwtService} from '@/core/services/jwt.service'

export const useAuthLoginRef = defineStore("authloginref", () => {
  const phoneNumber = ref("9801247011");
  const password = ref("norvic@123");
  return { phoneNumber, password};
});

export const useAuthLogin = defineStore("authlogin", {
  state: () => ({
    errors: null,
    loaderStatus:false,
    pdfData: null,

  }),
  getters:{
    getErrors:(state)=>{
        return state.errors
    },
    getLoaderStatus:(state)=>{
      return state.loaderStatus
    },
    
  },
  actions: {

    errorClear(){
      this.errors = null;
    },
    
    async submitLogin(credentials) {
      const jwtService = useJwtService();
      this.errors = null;
      try {
        this.loaderStatus =  true;
        await axios
          .post("/login", credentials)
          .then((response) => {
            this.loaderStatus =  false;
             if(response.data.status==200){
              var token = response.data.data.token;
              var has_multiple_account = response.data.data.has_multiple_account;
              var opd_id = window.localStorage.getItem('opd_id')
              jwtService.saveToken(token);
              const manageUserStore =  useManageUser()
              manageUserStore.setUser(response.data.data.user_info)
              manageUserStore.setIsAuthorized(true)
              if(has_multiple_account && !opd_id){
                const componentCardProfile = useComponentCardProfile();
                const selectAccountStore =  useSelectAccount();
                componentCardProfile.selectAccount(response.data.data.user_info.patient_id,response.data.data.token);
                selectAccountStore.setSelectToken(response.data.data.token)
                this.$router.push({name:'page.manage.selectaccount'});
              }else if(opd_id){
                this.$router.push({name: 'page.appointmentdoctor', params: { id: opd_id } })
  
              }
              else {
                this.$router.push({name:'page.dashboard'});
              }
             }else{
              manageUserStore.setIsAuthorized(false)
             }
           
          })
          .catch((error) => {
            
            this.errors =  error.response.data; manageUserStore.setIsAuthorized(false)
            this.loaderStatus =  false;
          });
      } catch (error) {
        this.loaderStatus =  false;
      }
    },  
    // async savePatient(credentials) {
    //   this.errors = null;
    //   try {
    //     this.loaderStatus =  true;
    //     await axios
    //       .post("/bookAppointment", credentials)
    //       .then((response) => {
    //         this.loaderStatus =  false;
    //         if(response.status === 200) {
    //           this.successresponse = response.data;
    //           Swal.fire({
    //             title: response.data.message,
    //             text: 'Date and Time :' + response.data.appointment_date + '' + response.data.appointment_time,
    //             icon: 'success',
    //             confirmButtonText: 'OK'
    //           })
    //           .then(() => {
    //             this.$router.push({ name: "page.successappointment" });
    //         });
            
    //         }
           
    //       })
    //       .catch((error) => {
    //         this.errors = error.response.data;
    //         this.loaderStatus =  false;
    //       });
    //   } catch (error) {
    //     this.errors = error.response.data;
    //     this.loaderStatus =  false;
    //   }
    // },
  },
});

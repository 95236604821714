<template>
    <!-- <div class="row"> -->
        <div class="col-sm-12">
            <div class="rounded shadow-sm">
                <div class="osahan-privacy bg-white rounded shadow-sm p-4">
                    <!-- <div class="row">
                        <div class="col-sm-2" style="cursor: pointer;" v-if="getIsAuthorized">
                            <router-link :to="{ name: 'page.dashboard' }">
                                <font-awesome-icon icon="fa-solid fa-arrow-left" style=" margin-right: 10px;" />
                                <span>Back</span>
                            </router-link>
                        </div>
                    </div> -->

                    <div class="row appointment-header ">
                        <!-- <div class="appointment-tabs col-sm-10">
                            <tabList @click="clickTab(tabs, index)" :class="{ activeTab: index == selectedTab }"
                                class="dateTabList" v-for="(tabs, index) in appointment_tabs" :key="index" :date="tabs">
                            </tabList>
                        </div> -->
                        <div  :class="getIsAuthorized ? 'col-sm-5' : ''" style="cursor: pointer;" v-if="getIsAuthorized">
                            <router-link :to="{ name: 'page.dashboard'  }" >
                                <font-awesome-icon icon="fa-solid fa-arrow-left" style=" margin-right: 10px;" />
                                <span>Back</span>
                            </router-link>
                                    
                            
                        </div>
                        <div :class="getIsAuthorized ? 'col-sm-5' : 'col-sm-10 col-md-8 col-xl-10'">
                            <div class="all-department">
                                <span style="font-size: var(--heading-3---medium)" class="fw-bold">All Departments</span>
                            </div>
                        </div>
                        <div class="col-sm-2" :class="getIsAuthorized ? 'col-sm-2' : 'col-md-4 col-xl-2'" style="position: relative">
                            <input type="text" v-model="filter" class="form form-control"
                                placeholder="Search Department" />
                            <font-awesome-icon class="searchIcon" icon="fa-solid fa-search" />
                        </div>
                    </div>


                    <SpinnerDataLoader :classname="'text-primary'" v-if="getLoaderStatus"></SpinnerDataLoader>
                    <noRecordComp v-if="filteredSpecialites.length < 1 && !getLoaderStatus"
                        :title="'NO RECORD FOUND'">
                    </noRecordComp>

                    <div v-if="!getLoaderStatus" class="row department-list">
                        <div class="col-12 col-md-4 col-xl-3" v-for="(department, index) in filteredSpecialites" :key="index">
                            <div class="packages"
                                @click="fetchDoctors(department.id)">
                                <div class="package_name">
                                    <span class="fo-text-color-primary-p500 fw-bold">{{
                                        department.department
                                    }}</span>
                                </div>
                                <div class="right-icon">
                                    <font-awesome-icon class="fo-text-color-primary-p500" icon="fa-solid fa-arrow-right" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <multiplecharges :deptId = "department"></multiplecharges>

</template>

<script setup>
import { useRouter } from "vue-router";
import { onMounted, ref, computed } from "vue";
import { storeToRefs } from "pinia";
import tabList from "@/components/tabList/tabList";
import SpinnerDataLoader from "@/components/loaders/spinnerloader";
import noRecordComp from "@/components/norecord";
import { useSelfAppointment } from '@/core/modules/self/appointment';
import {useManageUser} from '@/core/modules/auth/user'
import multiplecharges from '@/components/modal/multiplecharges'
import { Modal } from 'bootstrap'
const manageUserStore  = useManageUser()
const { getIsAuthorized}  =  storeToRefs(useManageUser())
const store = useSelfAppointment();
const month_index = ref('');
const year = ref('');
const dept_id = ref(null)
// const is_reschedule = ref(false)
const { getAppointmentData, getLoaderStatus, getImmigrationDoctorDetail, getDepartmentListData, getMultiPriceCatDeptId } = storeToRefs(
    useSelfAppointment()
);
// const selectedTab = ref("0");
const filter = ref("");
// const appointment_tabs = ref([
//     "Hospital Appointment"
// ]);
const router = useRouter();

onMounted(async () => {
    // store.fetchImmigrationDoctorDetails();
    await store.getDepartmentList();
})

function fetchDoctors(id) {
    if(id == getMultiPriceCatDeptId.value[0]){
        dept_id.value = id;
        let  modal1 = Modal.getOrCreateInstance('#multiplecharges');
        modal1.show();
    }
    else {
     store.getDepartmentOpdCharge(id);
     store.getDoctors(id);
    }
}
const department = computed(() => {
    return dept_id.value
})
// function clickTab(tabs, index) {
//     if (index === 1) {
//         store.getScheduleDates(getImmigrationDoctorDetail.value.id, getImmigrationDoctorDetail.value.speciality_id, year.value, month_index.value, getImmigrationDoctorDetail.value, is_reschedule.value)
//     }
//     else if (index === 2) {
//         store.getScheduleDates(getImmigrationDoctorDetail.value.id, getImmigrationDoctorDetail.value.speciality_id, year.value, month_index.value, getImmigrationDoctorDetail.value, is_reschedule.value)
//     }
// }

// function fetchScheduleDates(id, dept, doc) {
//     store.getScheduleDates(id, dept, year.value, month_index.value, doc)
// }

const filteredSpecialites = computed(() => {
    let filteredData = getDepartmentListData.value;
    if (filteredData)
        return getDepartmentListData.value.filter((appointment) => {
            return appointment.department
                .toLowerCase()
                .includes(filter.value.toLowerCase());
        });
    else return getDepartmentListData;
});
// const filteredDoctors = computed(() => {
//     let filteredData = getAppointmentData.value.doctors;
//     if (filteredData)
//         return getAppointmentData.value.doctors.filter((appointment) => {
//             return appointment.doctor_name
//                 .toLowerCase()
//                 .includes(filter.value.toLowerCase());
//         });
//     else return filteredData;
// });
</script>

<style scoped>
.appointment-tabs {
    display: flex;
}
</style>

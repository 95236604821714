<template>
    <div class="row">
        <cancelwellness :invoice="modalValue" :package_name="packagename" :wellness_tab="activeTabName"></cancelwellness>
        <div class="col-sm-12" v-if="getTodayData.length > 0">
            <span style="color:var(--foundation----primary---p200)" class="fw-700">Today</span>
            <SpinnerDataLoader :classname="'text-primary'" v-if="getLoaderStatus"></SpinnerDataLoader>

            <!-- Wellness Table for Today Data -->
            <div style="margin-bottom: 20px; overflow-x: scroll;" v-if="!getLoaderStatus && getTodayData.length > 0">
                <table class="self-table wellness-table" >
                    <thead>
                        <tr>
                            <th>Invoice Number</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Item</th>
                            <th>Amount</th>
                            <th ></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="tableRow" v-for="(wellness, index) in displayedTodayRecord" :key="index">
                            <td>{{ wellness.invoice_no }}</td>
                            <td>{{ wellness.appointment_date }}</td>
                            <td>{{ wellness.appointment_tme }}</td>
                            <td>{{ wellness.name }}</td>
                            <td>Rs. {{ wellness.amount }}</td>
                            <td><button @click="invoice_number_fun(wellness.invoice_no, wellness.name)"
                                    data-bs-toggle="modal" data-bs-target="#cancelwellness"
                                    class="btn btn-primary float-r">Cancel</button></td>
                        </tr>
                    </tbody>
                </table>
                <pagination v-model="todayPage" :records="getTodayData.length" :per-page="perPage" @paginate="myCallback" />
            </div>
            <!--End of Wellness Table for Today Data -->
        </div>
        <div class="col-sm-12">
            <div class="wellness-tabs">
                <tabList :class="{ activeTab: index == selectedTab }" @click="clickTab(tabs, index)" class="dateTabList"
                    v-for="(tabs, index) in wellness_tabs" :key="index" :date="tabs"></tabList>
                <div style="position: absolute; right: 0" class="new-wellness">
                    <router-link :to="{ name: 'page.wellnesspackage' }"> <button class=" btn btn-primary">New
                            Wellness</button> </router-link>
                </div>
            </div>
            <noRecordComp v-if="getWellnessData.length < 1 && !getLoaderStatus" :title="'NO RECORD FOUND'"></noRecordComp>
            <SpinnerDataLoader :classname="'text-primary'" v-if="getLoaderStatus"></SpinnerDataLoader>

            <!-- Wellness Table for Upcoming/Previous Data -->
            <div style="overflow: scroll; height: 50vh" v-if="!getLoaderStatus && getWellnessData.length > 0">
                <table class="self-table wellness-table" >
                    <thead>
                        <tr>
                            <th>Invoice Number</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Item</th>
                            <th>Amount</th>
                            <th ></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="tableRow" v-for="(wellness, index) in displayedRecords" :key="index">
                            <td>{{ wellness.invoice_no }}</td>
                            <td>{{ wellness.appointment_date }}</td>
                            <td>{{ wellness.appointment_tme }}</td>
                            <td>{{ wellness.name }}</td>
                            <td>Rs. {{ wellness.amount }}</td>
                            <td class="text-center"><button @click="invoice_number_fun(wellness.invoice_no, wellness.name)"
                                    data-bs-toggle="modal" data-bs-target="#cancelwellness" v-if="selectedTab == 0"
                                    class="btn btn-primary float-r">Cancel</button></td>
                        </tr>
                    </tbody>
                </table>
                <pagination v-model="page" :records="getWellnessData.length" :per-page="perPage" @paginate="myCallback" />
            </div>
            <!-- End of Wellness Table for Upcoming/Previous Data -->
        </div>


    </div>
</template>
<script setup>
import '@/assets/css/pagination.css'
import { useManageUser } from '@/core/modules/auth/user'
import Pagination from 'v-pagination-3';
import { onMounted, computed, ref } from 'vue';
import { storeToRefs } from 'pinia'
import tabList from '@/components/tabList/tabList';
import SpinnerDataLoader from '@/components/loaders/spinnerloader'
import noRecordComp from '@/components/norecord'
import { useSelfWellness } from "@/core/modules/self/wellness"
import cancelwellness from '@/components/modal/cancelwellness'
const store = useSelfWellness()
const wellness_tabs = ref(['upcoming', 'previous'])
var selectedTab = ref('0')
var activeTabName = ref("upcoming")
var modalValue = ref("")
var packagename = ref('')
const page = ref(1)
const todayPage = ref(1)
const perPage = ref(4)

const { getUser } = storeToRefs(useManageUser())
const { getLoaderStatus, getWellnessData, getTodayData, getErrors } = storeToRefs(useSelfWellness())

onMounted(() => {
    store.getWellness(`upcoming`, getUser.value.patient_id)
})

// Fetch Data according to the Previous/Upcoming Tab
function clickTab(tabs, index) {
    selectedTab.value = index;
    activeTabName.value = tabs
    store.getWellness(tabs, getUser.value.patient_id)
}

// Pass as props to cancel wellness package
function invoice_number_fun(inv, pack) {
    modalValue.value = inv;
    packagename.value = pack;
}
function myCallback() {

}

// Pagination 
const displayedRecords = computed(() => {
    const startIndex = perPage.value * (page.value - 1);
    const endIndex = startIndex + perPage.value;
    return getWellnessData.value.slice(startIndex, endIndex)
})
const displayedTodayRecord = computed(() => {
    const startIndex = perPage.value * (todayPage.value - 1);
    const endIndex = startIndex + perPage.value;
    return getTodayData.value.slice(startIndex, endIndex)
})
</script>

<style>.width-30 {
    width: 30px
}</style>
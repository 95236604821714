<template>
     <!-- <div class="row"> -->
          <div class="col-sm-9">
               <div class="row dashboard-container">
                    <div class="patient-img col-sm-3 m-none">
                         <img style="border-radius: 50%;" :src=user alt="patient-img">
                    </div>
                    <div class="dashboard-intro col-sm-9">
                         <span><span v-if="d < 12 && d > 5">Good Morning !</span> 
                         <span v-else-if="d >=11 && d <18">Good Afternoon !</span>
                         <span v-else>Good Evening !</span>
                         {{ getUser.patient_name }}</span>
                         <br>
                         <span class="fw-bold m-none" style="font-size:var(--heading-2---medium);">Find a doctor and make an</span> <br>
                         <span class="m-none" style="font-size:var(--heading-5---medium) ">appointment online</span>
                         <router-link :to="{ name: 'page.appointment' }" class="widget-header me-4 text-white btn b-none" style="background-color: var(--foundation----secondary---s100);">
                              <div class="icon d-flex align-items-center">
                                  <i class="feather-disc h6 me-2 mb-0"></i> <span>Make an Appointment</span>
                              </div>
                          </router-link>
                    </div>
               </div>
               <div class="row department-list ">
                    <span style="margin-top:20px;" class="fw-bold">Your Medical Details</span>
                        <div class="col-sm-4" v-for="(quick_links, index) in dashboard_quink_links" :key="index">
                            <div class="medical_details" :style="{ 'background-color' : quick_links.color}" >
                                <div class="doc_intro">
                                    <div class="doc_description" style="text-align: center">
                                         <span style="font-size: var(--heading-3---regular)" class="fo-text-color-white">{{quick_links.total}}</span>
                                        <span class="fo-text-color-white fw-bold">{{ quick_links.link_name }}</span>
                                    </div>
                                </div>
                                <div class="right-icon">
                                    <font-awesome-icon :style="{ 'background-color' : quick_links.icon_color}" style="font-size: 28px" class="fo-text-color-white dashbaord_icon" :icon="quick_links.icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="getAppointmentHistory.length > 0" class="row department-list" style="height: 280px !important; overflow: scroll;">
                    <span style="margin-top:20px; font-style: italic;" class="fw-bold">Upcoming Appointments</span>
                        <div class="col-sm-4" v-for="(appointment, index) in getAppointmentHistory" :key="index">
                            <div class="appointment-card">
                                <div class="doc_intro">
                                    <div class="description-appoinment" style="display: inline-grid;">
                                        <div class="patient-img">
                                        <img style="height: 50px; width: 50px; border-radius: 50%;" :src="appointment.doc_img" alt="soc">
                                   </div>
                                        <div style="display: inline-grid;" class="patient-img" >
                                         <span class="fo-text-color-white">{{appointment.doctor_name}}</span>
                                         <span class="fo-text-color-white">{{appointment.department}}</span>
                                         <span class="fo-text-color-white paid_appointment" v-show="appointment.can_pay === 0">Paid</span>
                                         <span class="fo-text-color-white unpaid_appointment" v-show="appointment.can_pay === 1">Unpaid</span>
                                         <span class="fo-text-color-white">{{ appointment.date }} {{ appointment.st_fulltime }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
          </div>
          <!-- <div class="col-sm-3">
               <span class="fw-bold">
                    All Specialist
               </span>
               <div class="dashboard-specialist-container">
                    <div class="row department-list">
                        <div class="col-sm-12" v-for="(department, index) in getAppointmentData.specialities" :key="index">  
                            <div class="packages fo-bg-color-primary-p50" style="justify-content: space-around !important">
                                <div class="package_name">
                                    <span class="fo-text-color-primary-p500 fw-bold">{{
                                        department.name
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
               </div>
          </div> -->
     <!-- </div> -->
</template>

<script setup>
import router from '@/router/routes'
import { onMounted, ref, watch, onBeforeMount, onBeforeUnmount } from 'vue';
import {useManageUser} from '@/core/modules/auth/user'
import { storeToRefs } from 'pinia'
import { useSelfAppointment } from '@/core/modules/self/appointment'
import { useSelfPrescription } from "@/core/modules/self/prescriptions"
import { useSelfInvestigationReport } from "@/core/modules/self/investigationreport"
import { useSelfWellness } from "@/core/modules/self/wellness"
import { useSelfVitals } from "@/core/modules/self/vitals"
const { getVitalsData } = storeToRefs(useSelfVitals())
const { getWellnessData } = storeToRefs(useSelfWellness())
const { getInvestigationReportDashboard } = storeToRefs(useSelfInvestigationReport())
const { getPatientPrescriptionDashboard } = storeToRefs(useSelfPrescription())
const { getAppointmentHistory, getAppointmentData } = storeToRefs(useSelfAppointment())
const {getUser} =  storeToRefs(useManageUser())
const appointment = useSelfAppointment();
const prescription = useSelfPrescription();
const investigation = useSelfInvestigationReport();
const wellness = useSelfWellness();
const vitals = useSelfVitals();
const user = ref('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6FI5wnFojJPN6gWk1lF7mIAdzd3D8dhQaHVRd1snFHw&s')
const date = new Date();
let d = date.getHours();
const dashboard_quink_links = ref([
     {link_name :'My Prescriptions',
     icon: 'fa-solid fa-pills',
     total: getPatientPrescriptionDashboard.value.length,
     color: 'var(--foundation----success---s100)',
     icon_color: 'var(--foundation----success---s300)',
     route_name: 'page.self.prescriptions'
     
     },
     {link_name :'My Vitals',
     icon: 'fa-solid fa-heart-pulse',
     total: getVitalsData.value.length,
     color:  'var(--foundation----warning---w100)',
     icon_color: 'var(--foundation----warning---w300)',
     route_name: 'page.self.vitals'
     },
     {link_name :'My Appointments',
     icon: 'fa-solid fa-calendar-check',
     total: getAppointmentHistory.value.length,
     color: 'var(--foundation----danger---d100)',
     icon_color: 'var(--foundation----danger---d300)',
     route_name: 'page.self.appointment'
     },
     {link_name :'My Purchase History',
     icon: 'fa-solid fa-clock-rotate-left',
     total: 0,
     color: 'var(--foundation----secondary---s100)',
     icon_color: 'var(--foundation----secondary---s300)',
     route_name: 'page.self.purchase-history'
     },
     {link_name :'My Wellness',
     icon: 'fa-solid fa-spa',
     total: getWellnessData.value.length,
     color: 'var(--foundation----primary---p100)',
     icon_color: 'var(--foundation----primary---p300)',
     route_name: 'page.self.wellness'
     },
     {link_name :'My Investigation Reports',
     icon: 'fa-regular fa-rectangle-list',
     total: getInvestigationReportDashboard.value.length,
     color: 'var(--foundation----success---s100)',
     icon_color: 'var(--foundation----success---s300)',
     route_name: 'page.self.investigation'
     }

])
onMounted(() => {
    appointment.fetchAppointmentHistory('upcoming',getUser.value.patient_id);
    appointment.getAppointment()
    prescription.getPatientPrescriptionDashboardData();
    investigation.getInvestigationReportDashboardData();
    wellness.getWellness(`upcoming`,getUser.value.patient_id )
    vitals.getVitals()  
})

watch(() => dashboard_quink_links.value.total, (newTotal, oldTotal) => {
     wellness.getWellness(`upcoming`,getUser.value.patient_id )
})

onBeforeMount(() => {
  window.addEventListener('beforeunload', handleBeforeUnload)
})
onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', handleBeforeUnload)
})

const handleBeforeUnload = () => {
  appointment.fetchAppointmentHistory('upcoming',getUser.value.patient_id);
    appointment.getAppointment()
    prescription.getPatientPrescriptionDashboardData();
    investigation.getInvestigationReportDashboardData();
    wellness.getWellness(`upcoming`,getUser.value.patient_id )
    vitals.getVitals()  
}

function routeToPage(route){
     console.log('yo')
     router.push({name: route})
}
</script>

<style scoped>
.dashboard-container {
     background-color: var(--foundation----primary---p100);
     padding: 12px;
     margin: 4px;
     border-radius: 20px;
     color: var(--foundation---shades---s0);
}
.patient-img {
     text-align: center;
}
.dashboard-intro {
     position: relative;
     top: 30px;
}
.medical_details {
     background-color: var( --foundation----secondary---s100);
     display: flex;
    justify-content: space-evenly;
    border: none;
    padding: 32px 20px;
    border-radius: 10px;
    margin: 10px 0px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: transform .2s; 
}

.medical_details:hover{
     transform: scale(1.05);
}

.appointment-card {
     background-color: var( --foundation----secondary---s100);
     display: flex;
     justify-content: space-evenly;
     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
     border-radius: 10px;
     cursor: pointer;
     padding: 15px 10px;
     margin: 10px 0px;
}
.dashboard-specialist-container {
     height: calc(100vh - 200px);
     overflow-y: scroll;
     padding: 10px;
     overflow-x: hidden;
}

.paid_appointment {
     background-color: var(--foundation----success---s100);
     border-radius: 20px;
     padding: 5px ;
     margin: 10px;
     /* width: 100px; */
}
.unpaid_appointment {
     background-color: var(--foundation----danger---d100);
     border-radius: 20px;
     padding: 5px 0px;
     margin: 10px;
     /* width: 100px */
}
.dashbaord_icon {
     padding: 10px;
     border-radius: 5px;
}
</style>
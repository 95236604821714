<template>
    <div class="bg-white profile-details m-none" v-if="!getDataLoading">
        <div v-for="(menu,index) in getMenus" :key="index" @click="setSelectedMenu(index)">
            <a  v-if="menu.show" class="cursor-pointer d-flex w-100 align-items-center border-bottom px-3 py-4" :class="getRouteName==menu.route_name?'fo-bg-color-primary-p100 text-default fo-text-color-white':''">
            <div class="left me-3">
              
                <h6 class="fw-bold m-0 text-dark" :class="getRouteName==menu.route_name?'fo-text-color-white':''">
                    <font-awesome-icon :icon="menu.icon" class="mx-1 "/>
                    {{menu.title}}</h6>
            </div>
            <div class="right ms-auto">
                <span class="fw-bold m-0"> <font-awesome-icon v-if="getRouteName!=menu.route_name" icon="fa-solid fa-arrow-right" class="h6 m-0"/></span>
            </div>
        </a>
        </div>
      
    </div>
    <div class="bg-white profile-details" v-else style="min-height: 300px;">
        <SpinnerDataLoader :classname="'text-primary'" style="margin-top: 200px;"></SpinnerDataLoader>
    </div>
    
</template>

<script setup>
import { useComponetSideNavItem } from '@/core/modules/components/sidenav/item'
import SpinnerDataLoader from '@/components/loaders/spinnerloader'
import {useCommonStore} from '@/core/modules/common'
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';
const componentSideNavItem = useComponetSideNavItem();
const commonStore = useCommonStore();
const { getMenus, getDataLoading, getSvgLink } = storeToRefs(useComponetSideNavItem())
const {getRouteName } = storeToRefs(useCommonStore())

onMounted(() => {
    // componentSideNavItem.setRouteName();
    // const menuStatus = window.localStorage.getItem('menu_status')!=1;
    // // alert(menuStatus);
    // if(menuStatus){
        componentSideNavItem.fetchMenus();
    // }
  
})
function setSelectedMenu(index){
    componentSideNavItem.setSeletedMenu(index);
}
</script>
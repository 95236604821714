<template>
    <div class="row">
        <div v-if="!getLoaderStatus && getVitalsData.length > 0" class="d-flex col-sm-2" style="position: relative;">
            <input class="form-control" type="text" v-model="filter" placeholder="Filter">
            <font-awesome-icon style="position: absolute; right: 20px; top: 12; color: var(--foundation----neutral---n100); font-size: var(--small---regular)" icon="fa-solid fa-search"/>
            </div>
        <div class="col-sm-12">
            
            <div class="vital-container" v-if="!getLoaderStatus && getLatestVitalData">
                <div class="vital-container-title" >
                    <span>Date : {{ getLatestVitalData.app_date }}</span>
                </div>
                <div class="vital-icons">
                    <div class="vital-icons-div">
                        <div class="vital-div-child" >
                            <img style="left: 18px;" class="position-r" src="@/assets/icons/bmi.svg" alt="bmi">
                            
                            <span class="vitals-span-title">BMI</span>
                            
                            <span class="vitals-span">{{ getLatestVitalData.bmi }} kg/m2</span>
                        </div>
                        <div class="vital-div-child">
                            <img style="left: 15px;" class="position-r" src="@/assets/icons/height.svg" alt="height">
                            
                            <span class="vitals-span-title">Height</span>
                            
                            <span class="vitals-span">{{ getLatestVitalData.height }}</span>
                        </div>
                        <div class="vital-div-child">
                            <img style=" height: 25px; left: 13px" class="position-r" src="@/assets/icons/weight.png" alt="weight">
                            
                            <span class="vitals-span-title">Weight</span>
                            
                            <span class="vitals-span">{{ getLatestVitalData.weight }}</span>
                        </div>
                        <div class="vital-div-child">
                            <img style="left: 20px;" class="position-r" src="@/assets/icons/blood_pressure.svg" alt="blood_pressure">
                            
                            <span class="vitals-span-title">Blood Pressure</span>
                            
                            <span class="vitals-span">{{ getLatestVitalData.bp }} mmHg</span>
                        </div>
                        <div class="vital-div-child position-r" >
                            <img style="left: 10px;" class="position-r"  src="@/assets/icons/pulse.svg" alt="pulse">
                            
                            <span class="vitals-span-title">Pulse</span>
                            
                            <span class="vitals-span">{{ getLatestVitalData.pulse }} bpm</span>
                        </div>
                        <div class="vital-div-child">
                            <img class="position-r" style="left: 20px;" src="@/assets/icons/temperature.svg" alt="temperature">
                            
                            <span class="vitals-span-title">Temperature</span>
                            
                            <span class="vitals-span">{{ getLatestVitalData.temp }}°F</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            
            <noRecordComp v-if="getVitalsData.length < 1 && !getLoaderStatus" :title="'NO RECORD FOUND'"></noRecordComp>
            <SpinnerDataLoader :classname="'text-primary'" v-if="getLoaderStatus"></SpinnerDataLoader>
            <div style="overflow: scroll; height: 50vh" v-if="!getLoaderStatus && getVitalsData.length > 0">
                <table class="self-table">
                    <thead>
                        <tr>
                            <th>S.N</th>
                            <th>Date</th>
                            <th>BP (mmHg)</th>
                            <th>Temperature(°F)</th>
                            <th>Pulse (bpm)</th>
                            <th>BMI (kg/m2)</th>
                            <th>SPO2 (%)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <noRecordComp v-if="filteredVitalsData.length < 1 && !getLoaderStatus" :title="'NO RECORD FOUND'"></noRecordComp>
                        <tr class="tableRow" v-for="(vital, index) in filteredVitalsData" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>
                                {{ vital.app_date }}
                            </td>
                            <td>{{ vital.bp }}</td>
                            <td>{{ vital.temp }}</td>
                            <td>{{ vital.pulse }}</td>
                            <td>{{ vital.bmi }}</td>
                            <td>{{ vital.spo }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</template>
 
<script setup>
import { storeToRefs } from 'pinia'
import { onMounted, computed, ref } from 'vue';
import noRecordComp from '@/components/norecord'
import SpinnerDataLoader from '@/components/loaders/spinnerloader'
import { useSelfVitals } from "@/core/modules/self/vitals"
var { getVitalsData, getLoaderStatus, getLatestVitalData } = storeToRefs(useSelfVitals())
const store = useSelfVitals()
const filter = ref('')

onMounted(() => {
    store.getVitals()
})

const filteredVitalsData = computed(() => {
    let filteredData = getVitalsData.value.length;
    if(filteredData > 0)
  return getVitalsData.value.filter(vitals => {
    return vitals.temp.toLowerCase().includes(filter.value.toLowerCase()) ||
        vitals.bp.toLowerCase().includes(filter.value.toLowerCase())
  })
  else return getVitalsData
})

</script>

<template>
    <footer id="footer">
        <div class="row">
          <div class="col-sm-4">
            <img
            style="height: 32px"
              class="img-fluid footer-img"
              src="../assets/images/dolphinlogo.png"
              alt="dolphin"
            />
          </div>
          <div class="col-sm-4" style="line-height: 40px">
            <a style="color:var(--foundation----neutral---n10);" href="#">Privacy Policy </a> <span>| </span>
            <a style="color:var(--foundation----neutral---n10);" href="#">Terms of Use</a>
          </div>
          <div class="col-sm-4">
            <img
              class="img-fluid footer-img"
              src="../assets/images/mavorion.png"
              alt="dolphin"
            />
          </div>
        </div>
      </footer>
</template>
import axios from "axios";
import { defineStore } from "pinia";

export const useSelfPurchaseHistory = defineStore("selfPurchaseHistory", {
    state: () => ({
        errors: null,
        loaderStatus: false,
        purchaseHistoryData: ""
    }),
    getters: {
        getErrors: (state) => {
            return state.errors;
        },
        getLoaderStatus: (state) => {
            return state.loaderStatus
        },
        getPurchaseHistoryData: (state) => {
            return state.purchaseHistoryData
        }
    },
    actions: {
        async getPurchaseHistory() {
            try {
                this.setErrors(null)
                this.loaderStatus = true;
                await axios
                    .get("getItemPurchaseHistory")
                    .then((response) => {
                        this.loaderStatus = false;
                        this.purchaseHistoryData = response.data;
                    })
                    .catch((error) => {
                        this.setErrors(error.response.data)
                        this.loaderStatus = false;
                    })
            }
            catch(error) {
                console.log(error)
            }
        },
        setErrors(error) {
            this.errors = error;
        },
    }
})